import { useEffect, useCallback, useMemo, useReducer } from 'react';
import type { SurveyBuilderVersion } from '@/types';
import { indexBy } from '@/utils/array';
import { SurveyItemType } from '@enums';
import { useSubmitSurveyDraft, useSurveyBuilderState } from './hooks';
import { getTranslatedSurveyBuilderVersion, getSurveyTextKeys, extractQuestionTextKeys, extractMessageTextKeys } from './utils/language';
import { SurveyBuilderStateContext, SurveyLanguageEditorContext, SaveSurveyDraftContext } from './Context';
import { surveyStateReducer } from './state';

type Props = {
  languageCode: string;
  itemIdentifier: string;
} & ChildrenProps;

export const ItemLanguageEditingContainer = (props: Props) => {
  const [originalState, dispatchOriginal] = useSurveyBuilderState();
  const submitSurveyDraft = useSubmitSurveyDraft();

  const translateBuilderVersion = useCallback(() => {
    return {
      ...originalState,
      survey: getTranslatedSurveyBuilderVersion(props.languageCode, originalState.survey),
    };
  }, [originalState, props.languageCode]);

  const [state, dispatch] = useReducer(surveyStateReducer, null, translateBuilderVersion);

  const clearState = useCallback(() => {
    dispatch({
      type: 'replace-state',
      state: translateBuilderVersion(),
    });
  }, [dispatch, translateBuilderVersion]);

  const saveLanguageChanges = useCallback(() => {
    const keys = getKeys();

    if (keys.length) {
      dispatchOriginal({
        type: 'language-keys-imported',
        payload: {
          code: props.languageCode,
          values: indexBy(keys, k => k.key, v => v.value),
        },
      });
    }

    function getKeys() {
      const item = state.survey.items.find(i => i.identifier === props.itemIdentifier);

      switch (item.type) {
        case SurveyItemType.Question: {
          const question = state.survey.questions.find(q => q.base.identifier === item.source.identifier);
          return extractQuestionTextKeys([question], [item]);
        }
        case SurveyItemType.Message: {
          const message = state.survey.messages.find(m => m.identifier === item.source.identifier);

          return extractMessageTextKeys([message], [item]);
        }
        case SurveyItemType.AlternateImageExercise: {
          return [];
        }
        default: {
          throw new UnreachableCaseError(item.type);
        }
      }
    }

    submitSurveyDraft();
  }, [dispatchOriginal, props.itemIdentifier, props.languageCode, state.survey.items, state.survey.messages, state.survey.questions, submitSurveyDraft]);

  return (
    <SurveyBuilderStateContext.Provider value={[state, dispatch, clearState]}>
      <SurveyLanguageEditorContext.Provider value={{ saveLanguageChanges }}>
        <SaveSurveyDraftContext.Provider value={noop}>
          {props.children}
        </SaveSurveyDraftContext.Provider>
      </SurveyLanguageEditorContext.Provider>
    </SurveyBuilderStateContext.Provider>
  );
};

const noop = () => { };