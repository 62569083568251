import { useMemo, useCallback } from 'react';
import cuid from 'cuid';
import { useQuestionBuilderItemContext, useDeleteQuestion } from '@containers/SurveyBuilder.Question/Context';
import { useSurveyBuilderState } from '@containers/SurveyBuilder/hooks';
import { useSurveyBuilderItem, useSurveyBuilderItemEditing } from '@/containers/SurveyBuilder';
import { SurveyBuilderContextMenu } from '@/components/SurveyBuilder/ContextMenu';
import type { ContextMenuItemProps } from '@/components/SurveyBuilder/ContextMenu.Item';
import { useMoveSurveyItemModal } from '@/components/SurveyBuilder/Modal.MoveItem';
import { useCloneSurveyQuestionModal } from '@/components/SurveyBuilder/Modal.CloneQuestion';
import { SurveyQuestionType } from '@/enums';
import { useQuestionLogicModal } from './Modal.Logic';
import { useConditionalOrderingModal } from './ConditionalOrderingModal';

type Props = {
  canPreview?: boolean;
  onClickPreview?: () => void;
  className?: string;
};

export const QuestionContextMenu = ({ canPreview, className, onClickPreview }: Props) => {
  const question = useQuestionBuilderItemContext();
  const item = useSurveyBuilderItem();
  const { canEdit } = useSurveyBuilderItemEditing();

  const [surveyState, dispatch] = useSurveyBuilderState();

  const section = useMemo(() => {
    return surveyState.survey.sections.find(s => s.identifier === item.section.identifier);
  }, [surveyState, item.section.identifier]);

  const onDelete = useDeleteQuestion();
  const [toggleMoveModal, MoveSurveyItemModal] = useMoveSurveyItemModal();
  const [toggleLogicModal, QuestionLogicModal] = useQuestionLogicModal();
  const [toggleCloneModal, CloneQuestionModal] = useCloneSurveyQuestionModal();
  const [toggleConditionalOrdering, ConditionalOrderingModal] = useConditionalOrderingModal();

  const edit = useMemo(() => {
    return {
      children: 'Edit',
      disabled: !canEdit,
      onClick: () => {
        dispatch({
          type: 'toggle-editing',
          itemIdentifier: item.identifier,
        });
      },
    };
  }, [canEdit, dispatch, item.identifier]);

  const remove = useMemo(() => {
    return {
      children: 'Delete',
      disabled: !question.metadata.canDelete,
      onClick: onDelete,
    };
  }, [
    onDelete,
    question.metadata.canDelete,
  ]);

  const move = useMemo(() => {
    return {
      children: 'Move',
      // disabled: !question.metadata.canDelete,
      onClick: toggleMoveModal,
    };
  }, [toggleMoveModal]);

  const editLogic = useMemo(() => {
    return {
      children: 'Edit Logic',
      onClick: toggleLogicModal,
    };
  }, [
    toggleLogicModal,
  ]);

  const copy = useMemo(() => {
    return {
      children: 'Clone',
      onClick: toggleCloneModal,
    };
  }, [toggleCloneModal]);

  const anchor = useMemo(() => {
    if (!section.metadata.randomize) return null;

    return {
      children: 'Anchor',
      checked: !!question.metadata.anchor,
      onClick: () => {
        dispatch({
          type: 'question-metadata-updated',
          payload: {
            questionIdentifier: question.base.identifier,
            metadata: {
              anchor: !question.metadata.anchor,
            },
          },
        });
      },
    };
  }, [section.metadata.randomize, question.base.identifier, question.metadata.anchor, dispatch]);

  const preview = useMemo(() => {
    if (canPreview && typeof onClickPreview === 'function') {
      return {
        children: 'Preview',
        onClick: onClickPreview,
      };
    }

    return null;
  }, [
    canPreview,
    onClickPreview,
  ]);

  const conditionalOrdering = useMemo(() => {
    const sectionsAfterCount = surveyState.survey.sections.filter(s => s.ordinal > section.ordinal).length;

    if (question.typeId === SurveyQuestionType.Ranking) {
      return {
        children: 'Conditional Ordering',
        disabled: sectionsAfterCount < 1,
        onClick: toggleConditionalOrdering,
      };
    }
  }, [question.typeId, section.ordinal, surveyState.survey.sections, toggleConditionalOrdering]);

  const items: ContextMenuItemProps[] = [
    edit,
    editLogic,
    move,
    remove,
    copy,
    anchor,
    preview,
    conditionalOrdering,
  ].filter(Boolean);

  if (question.metadata.derivedType) {
    return null;
  }

  return (
    <>
      <SurveyBuilderContextMenu
        className={className}
        items={items} />
      <MoveSurveyItemModal item={item} />
      <QuestionLogicModal />
      <CloneQuestionModal item={item} question={question} />
      <ConditionalOrderingModal questionIdentifier={question.base.identifier} />
    </>
  );
};

export default QuestionContextMenu;