import { useCallback } from 'react';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { cx } from '@utils';
import { MoreVerticalAnchorSmall } from '@/presentation/Anchor';
import { useZIndexModifier, usePopperState, PopperMenu, PopperMenuItem, ParentPopperMenuItem } from '@/components/Popper';
import type { ContextMenuItem, ContextMenuProps } from './interfaces';
import styles from './styles.css';

export const ContextMenu = ({ className, AnchorElement = MoreVerticalAnchorSmall, items, options = {} }: ContextMenuProps) => {
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { setOpen, ...popperState } = usePopperState({
    modifiers: [
      zIndexModifier,
    ],
    placement: options.placement ?? 'right-start',
  });

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <div
        className={cx(styles.anchor, className)}
        onClick={() => setOpen(!popperState.open)}
        ref={popperState.setReferenceElement}>
        <AnchorElement />
      </div>
      {popperState.open && (
        <Popper
          open={popperState.open}
          ref={popperState.setPopperElement}
          style={popperState.styles.popper}
          anchorEl={popperState.referenceElement}
          placement={options.placement ?? 'right-start'}
          {...popperState.attributes.popper}>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            touchEvent='onTouchStart'
            onClickAway={closeMenu}>
            <PopperMenu>
              {items.filter(i => !i.hidden).map((item, index) => (
                <MenuItem
                  key={index}
                  item={item}
                  onClose={closeMenu} />
              ))}
            </PopperMenu>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};

type MenuItemProps = {
  item: ContextMenuItem;
  onClose: () => void;
};

const MenuItem = ({ item, ...props }: MenuItemProps) => {
  if (item.children?.length) {
    return <ParentMenuItem item={item} {...props} />;
  } else {
    return (
      <PopperMenuItem
        disabled={item.disabled}
        onClick={() => {
          item.onClick();
          props.onClose();
        }}>
        {typeof item.label === 'string' ? item.label : <item.label />}
      </PopperMenuItem>
    );
  }
};

const ParentMenuItem = ({ item, ...props }: MenuItemProps) => {
  const zIndexModifier = useZIndexModifier({ zIndex: 8 });
  const { open, setOpen, setPopperElement, setReferenceElement, styles: popperStyles, attributes } = usePopperState({
    placement: 'right',
    modifiers: [zIndexModifier],
  });

  return (
    <>
      <ParentPopperMenuItem
        ref={setReferenceElement}
        disabled={item.disabled}
        onClick={() => setOpen(true)}>
        {typeof item.label === 'string' ? item.label : <item.label />}
      </ParentPopperMenuItem>
      {open && (
        <ClickAwayListener
          onClickAway={() => setOpen(false)}>
          <div
            ref={setPopperElement}
            {...attributes.popper}
            style={popperStyles.popper}>
            <PopperMenu>
              {item.children.map((child, index) => (
                <MenuItem
                  key={index}
                  item={child}
                  {...props} />
              ))}
            </PopperMenu>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};