import { useCallback, useMemo } from 'react';
import { SurveyLogicBuilderContainer } from '@/containers/SurveyBuilder.Logic';
import { useMatchProjectIdFromUrl } from '@/containers/GroupProject/hooks';
import SurveySectionsBuilder from './SectionsBuilder';
import SurveyBuilder from './SurveyBuilder';
import { SurveyQuotasBuilder } from './QuotasBuilder';
import { SurveyLogicBuilder } from './LogicBuilder';
import { SurveyTaggingBuilder } from './TaggingBuilder';
import { SurveySettings } from './SurveySettings';
import SurveyTab from './SurveyTab';

type Props = {
  tabs?: SurveyTab[];
} & IProjectId;

const defaultTabs = [
  SurveyTab.Questions,
  SurveyTab.Logic,
  SurveyTab.Tagging,
  SurveyTab.Quotas,
  SurveyTab.Settings,
];

export const ProjectSurveyBuilder = ({
  tabs: tabParams = defaultTabs,
  projectId,
}: Props) => {

  //const { projectId } = useMatchProjectIdFromUrl();

  const tabs = useMemo(() => {
    if (!projectId) return tabParams.filter(t => t != SurveyTab.Settings);

    return tabParams;
  }, [projectId, tabParams]);

  const renderTab = useCallback((tab: SurveyTab) => {
    switch (tab) {
      case SurveyTab.Logic:
        return (
          <SurveyLogicBuilderContainer>
            <SurveyLogicBuilder />
          </SurveyLogicBuilderContainer>
        );

      case SurveyTab.Tagging:
        return (
          <SurveyTaggingBuilder />
        );

      case SurveyTab.Questions:
        return (
          <SurveySectionsBuilder />
        );

      case SurveyTab.Quotas:
        return (
          <SurveyQuotasBuilder />
        );

      case SurveyTab.Settings:
        return (
          <SurveySettings projectId={projectId} />
        );
    }

  }, [projectId]);

  return (
    <SurveyBuilder
      tabs={tabs}
      renderTab={renderTab} />
  );
};

export default ProjectSurveyBuilder;